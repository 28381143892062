<template>
  <div class="type" v-click-outside="closeMenu">
    <div class="type__icon" v-if="placement === 'system'">
      <img
        src="@/assets/img/icons/single-system--type-tn.svg"
        alt="TN"
        v-if="!sectorFromState.isCustom"
      />
      <img src="@/assets/img/icons/single-system--type-custom.svg" alt="custom" v-else />
    </div>
    <div class="type__icon" v-else>
      <img
        src="@/assets/img/icons/single-junction--type-tn.svg"
        alt="TN"
        v-if="!currentJunction.isCustom"
      />
      <img src="@/assets/img/icons/single-junction--type-custom.svg" alt="custom" v-else />
    </div>
    <div class="type__text" v-if="placement === 'system'">
      <i18n
        :path="
          sectorFromState.isCustom
            ? `message.${placement}.pane.typeCustom`
            : `message.${placement}.pane.typeTn`
        "
      >
        <template #break>
          <br />
        </template>
      </i18n>
    </div>
    <div class="type__text" v-else>
      <i18n
        :path="
          currentJunction.isCustom
            ? `message.${placement}.pane.typeCustom`
            : `message.${placement}.pane.typeTn`
        "
      >
        <template #break>
          <br />
        </template>
      </i18n>
    </div>
    <button class="type__btn" @click="isSubmenuShow = !isSubmenuShow">
      <img src="@/assets/img/icons/single-system--type-btn.svg" alt="menu" v-if="!isSubmenuShow" />
      <img src="@/assets/img/icons/uploader--modal-close.svg" alt="❌" v-else />
    </button>
    <div class="type__submenu" v-if="isSubmenuShow">
      <button class="button" @click="makeCustom" v-if="isShowMakeCustomBtn()">
        <span class="icon">
          <img src="@/assets/img/icons/system--to-custom.svg" alt="→" />
        </span>
        <span class="text" v-text="getText('remake')"></span>
      </button>
      <button class="button" @click="makeDefault" v-if="isShowRestoreDefaultBtn()">
        <span class="icon">
          <img src="@/assets/img/icons/system--to-default.svg" alt="←" />
        </span>
        <span class="text" v-text="getText('restore')"></span>
      </button>
      <button class="button" @click="deleteMethod">
        <span class="icon">
          <img src="@/assets/img/icons/system--delete.svg" alt="" />
        </span>
        <span class="text">
          {{ $t('message.headPaneMenu.delete') }}
        </span>
      </button>
    </div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
    <remove-drawing-confirmation
      :is-modal-open="isRemoveDrawingShow"
      @close="toggleRemoveDrawingModal"
      @cancel="cancel"
      @confirm="confirm"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  props: {
    placement: String
  },
  components: {
    RemoveDrawingConfirmation: () =>
      import('@/components/elements/Modals/RemoveDrawingConfirmation')
  },
  data: () => ({
    isSubmenuShow: false,
    isLoading: false,
    isRemoveDrawingShow: false
  }),
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_PARAM: 'UPDATE_SECTOR_PARAM',
      UPDATE_JUNCTION_PARAM: 'UPDATE_JUNCTION_PARAM',
      RESTORE_SYSTEM_TO_DEFAULT: 'RESTORE_SYSTEM_TO_DEFAULT',
      RESTORE_JUNCTION_TO_DEFAULT: 'RESTORE_JUNCTION_TO_DEFAULT',
      DELETE_SECTOR: 'DELETE_SECTOR',
      DELETE_LAST_SECTOR: 'DELETE_LAST_SECTOR'
    }),
    ...mapActions({
      deleteFiles: 'deleteFiles',
      deleteJunctionFromPage: 'deleteJunctionFromPage',
      transformJunctionToCustom: 'transformJunctionToCustom',
      getItem: 'getItem'
    }),
    cancel() {
      this.transformJunctionToCustomMethod(true)
    },
    confirm() {
      this.transformJunctionToCustomMethod(false)
    },
    close() {
      this.toggleRemoveDrawingModal()
    },
    toggleRemoveDrawingModal() {
      this.isRemoveDrawingShow = !this.isRemoveDrawingShow
    },
    closeMenu() {
      if (this.isSubmenuShow) this.isSubmenuShow = false
    },
    getText(action) {
      if (action === 'remake') {
        return this.$t(`message.headPaneMenu.${this.placement}.remake`)
      }
      if (action === 'restore') {
        return this.$t(`message.headPaneMenu.${this.placement}.restore`)
      }
    },
    isShowMakeCustomBtn() {
      if (this.placement === 'system') {
        return !this.sectorFromState.isCustom
      } else {
        return !this.currentJunction.isCustom
      }
    },
    isShowRestoreDefaultBtn() {
      if (this.placement === 'system') {
        return this.sectorFromState.isCustom && this.sectorFromState.system !== ''
      } else {
        return this.currentJunction.isCustom && this.currentJunction.id !== ''
      }
    },
    makeCustom() {
      if (this.placement === 'system') {
        this.UPDATE_SECTOR_PARAM({
          index: this.sectorIndex,
          param: 'systemType',
          value: 'customSystem'
        })
        this.UPDATE_SECTOR_PARAM({
          index: this.sectorIndex,
          param: 'isCustom',
          value: true
        })
      } else {
        this.toggleRemoveDrawingModal()
      }
      this.isSubmenuShow = false
    },
    transformJunctionToCustomMethod(isSaveDrawing) {
      this.transformJunctionToCustom({
        sectorIndex: this.sectorIndex,
        junctionIndex: this.junctionIndex,
        isSaveDrawing,
        transformations: {
          junctionType: 'customJunction',
          isCustom: true
        },
        originalDrawingUrl: this.originalJunction.img_original
      })
      this.toggleRemoveDrawingModal()
    },
    makeDefault() {
      const { sectorIndex, junctionIndex } = this
      if (this.placement === 'system') {
        this.isLoading = true
        this.getItem({ type: 'system', sectorIndex, junctionIndex: null, variables: null }).then((response) => {
          this.RESTORE_SYSTEM_TO_DEFAULT({ sectorIndex, data: response.data })
          this.isLoading = false
        })
      } else {
        this.isLoading = true
        this.deleteDrawingUrl()
        this.getItem({ type: 'junction', sectorIndex, junctionIndex, variables: this.currentJunction.variables })
          .then((response) => {
            this.RESTORE_JUNCTION_TO_DEFAULT({
              sectorIndex,
              junctionIndex,
              junctionLayers: response.data.layers,
              type: this.originalJunction.type
            })
            this.isLoading = false
          })
      }
      this.isSubmenuShow = false
    },
    deleteDrawingUrl() {
      let files = []
      files.push(this.currentJunction.drawingUrl)
      this.deleteFiles({
        files: JSON.stringify(files),
        sectorIndex: this.sectorIndex,
        junctionIndex: this.junctionIndex
      }, this.$i18n.locale)
    },
    deleteMethod() {
      if (this.placement === 'system') {
        if (this.allSectors.length === 1) {
          this.$router.push(`/${this.$i18n.locale}/systems/0`)
          this.DELETE_LAST_SECTOR(0)
        } else {
          if (this.allSectors.length - 1 === parseInt(this.sectorIndex, 10)) {
            this.DELETE_SECTOR(this.sectorIndex)
            this.$router.push(`/${this.$i18n.locale}/system/${this.sectorIndex - 1}`)
          } else {
            this.DELETE_SECTOR(this.sectorIndex)
          }
        }
      } else {
        this.deleteJunctionFromPage({
          sectorIndex: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          lang: this.$i18n.locale
        })
      }

      this.isSubmenuShow = false
    }
  },
  computed: {
    ...mapState({
      allSectors: state => state.sectors,
      responseLayers: state => state.layers,
      responseJunctionLayers: state => state.junctionLayers,
      allJunctions: state => state.junctions
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    isSingleJunction() {
      return !Object.prototype.hasOwnProperty.call(this.currentJunction, 'groupId')
    },
    originalJunction() {
      return this.isSingleJunction ? this.originalSingleJunction : this.originalGroupJunction
    },
    originalSingleJunction() {
      return this.allJunctions.find(p => p.id === this.currentJunction.id)
    },
    originalGroupJunction() {
      const group = this.allJunctions.find(p => p.id === this.currentJunction.groupId).items
      return group.find(p => p.id === this.currentJunction.id)
    }
  }
}
</script>

<style scoped lang="sass">
.sector, .junction
  &-info
    &__pane
      .type
        // cursor: pointer
        @extend .head-controls__type
        &__text
          @extend .head-controls__type--text
        &__btn
          @extend .head-controls__type--btn
        &__submenu
          @extend .head-controls__type--submenu
</style>
