<script>
import InputOrSelect from '@/components/elements/Dom/InputOrSelect.vue'
import { isShowElement } from '@/utils/customFunctions'

export default {
  name: 'JunctionLayerItem',
  components: { InputOrSelect },
  props: ['isComplex', 'layerItem', 'layerIndex', 'layerItemIndex'],
  methods: {
    choseNewMaterial() {
      this.$emit('replaceMaterial', { layerItemName: this.layerItem.name, layerIndex: this.layerIndex, itemIndex: this.layerItemIndex })
    },
    isShowInputOrSelect(material) {
      return isShowElement(material) || this.layerItem.calcValueEditable
    },
    prepareComplexMaterial() {
      let { calcValue, calcValueEditable, materialRate } = this.layerItem

      return {
        ...this.layerItem.material,
        value: calcValue,
        valueType: 'input',
        isDisabled: !calcValueEditable,
        materialRate,
        isComplex: true
      }
    },
    getTooltip() {
      return this.$t(`message.junction.layers.complex.${this.material.isDisabled ? 'notEditable' : 'editable'}`)
    }
  },
  computed: {
    getClasses() {
      return {
        'elements__left--full': !this.isShowInputOrSelect(this.material),
        'elements__left--disabled': !this.layerItem.hasAlternate
      }
    },
    material() {
      return this.isComplex ? this.prepareComplexMaterial() : this.layerItem.material
    }
  }
}
</script>

<template>
  <div class="elements">
    <div
        class="elements__left"
        @click="layerItem.hasAlternate ? choseNewMaterial() : null"
        :class="getClasses"
    >
      <div class="elements__left-info">
        <div class="name">{{ layerItem.name }}</div>
        <div class="material">{{ layerItem.material.name }}</div>
      </div>
      <div class="chevron">
        <img v-if="layerItem.hasAlternate" src="@/assets/img/icons/filter--select.svg" alt="↓" />
        <img v-else src="@/assets/img/icons/filter--select--disabled.svg" alt="↓" />
      </div>
    </div>
    <div
        v-if="isComplex"
        class="elements__tooltip"
        :content="getTooltip()"
        v-tippy="{ placement: 'top', maxWidth: 200 }">
    </div>
    <Input-or-select
        :item="material"
        v-if="isShowInputOrSelect(material)"
        parent-type="junction"
        :layerIndex="layerIndex"
        :isInCustom="false"
        :itemIndex="layerItemIndex"
    />
  </div>
</template>

<style scoped lang="sass">
.elements
  &__left-info
    display: inline-block
    vertical-align: baseline
  &__tooltip
    width: rem(40)
    height: rem(40)
    align-self: center
    background: url(~@/assets/img/icons/info.svg) no-repeat center center
</style>
