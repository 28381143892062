import { render, staticRenderFns } from "./InputOrSelect.vue?vue&type=template&id=556f3367&scoped=true&"
import script from "./InputOrSelect.vue?vue&type=script&lang=js&"
export * from "./InputOrSelect.vue?vue&type=script&lang=js&"
import style0 from "./InputOrSelect.vue?vue&type=style&index=0&id=556f3367&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "556f3367",
  null
  
)

export default component.exports